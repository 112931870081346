<template>
  <div class="material repeating-fields">
    <CRow>
      <CCol><h5 class="font-weight-bold" style="font-size: 1rem;">Material</h5></CCol>
    </CRow>
    <CRow class="labels" style="margin-left: 1rem;">
      <CCol class="d-none d-md-block col-md-2">
        Stück*
      </CCol>
      <CCol class="d-none d-md-block col-md-3">
        Art.-Nr.
      </CCol>
      <CCol class="d-none d-md-block col-md-7">
        Material
      </CCol>
    </CRow>
    <VueRepeaterLocal v-model="materialListe" identifier="formular-material"/>
    <div class="vorgaben mt-2 px-0">
      <CRow class="mt-2 border pl-1 pr-3 py-1 pt-2 mx-0" v-for="(item, index) in materialVorgabe" v-bind:key="index">
        <CCol class="col-10 px-0">
          <CRow>
            <CCol class="col-8 col-md-2 px-0">
              <MaterialText
                class=""
                form-label=""
                v-bind:value="item.stueckzahl"
                :isValid="true"
                disabled="disabled"
              />
            </CCol>
            <CCol class="d-none d-md-block px-0 col-md-3">
              <MaterialText
                :isValid="true"
                form-label=""
                rules=""
                placeholder="Artikel Nr."
                disabled="disabled"
              />
            </CCol>
            <CCol class="col-12 col-md-6 px-0">
              <MaterialText
                form-label=""
                v-bind:value="item.material"
                :isValid="true"
                disabled="disabled"
              />
            </CCol>
          </CRow>
        </CCol>

        <CCol class="col-2 text-right m-auto">
          <CButton
            color="danger"
            variant="outline"
            size="sm"
            class="ml-2 vertical-align-middle m-auto"
            v-c-tooltip="{content: 'Löschen', placement:'left'}"
            v-on:click="deleteEntry(item.id)"
            v-if="!item.aktiv"
          >
            <CIcon name="cil-trash"/>
          </CButton>
        </CCol>
      </CRow>
      <CRow>
        <CCol class="col-4"><h5 class="font-weight-bold" style="font-size: 1rem;"></h5></CCol>
        <CCol class="col-8 py-2 px-0 mx-0 right text-right">
          <CButton
            color="success"
            size="sm"
            class="right text-right"
            @click="addEntry()"
            v-c-tooltip="{content: 'Hinzufügen', placement:'left'}"
          >
            Material hinzufügen
            <CIcon name="cil-library-add"/>
          </CButton>
        </CCol>
      </CRow>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { EventBus } from '@/helpers/eventBus'
import VueRepeaterLocal from '@/components/VueRepeater/VueRepeaterLocal'
import MaterialText from '@/components/FormElements/Text'

export default {
  name: 'ProtokollMaterial',
  components: {
    VueRepeaterLocal,
    MaterialText
  },
  props: {
    value: {
      type: Array,
      required: false
    },
    materialVorgabe: {
      type: Array,
      required: false
    }
  },
  data () {
    return {
      bus: new Vue(),
      materialListe: [
        {
          name: 'formular-material',
          value: {}
        }
      ]
    }
  },
  mounted () {
    if (this.value) {
      const self = this
      this._.forEach(this.value, function (v) {
        self.materialListe.push({
          name: 'formular-material',
          value: v
        })
      })
    }
  },
  watch: {
    materialListe: function () {
      const results = []
      const copyMaterialListe = Object.assign(this.materialListe)
      this._.forEach(copyMaterialListe, function (mtrl) {
        if (mtrl.value) {
          const value = mtrl.value
          if ('stueckzahl' in value) {
            if (value.stueckzahl !== '') {
              results.push(value)
            }
          }
        }
      })
      this.$emit('input', results)
    }
  },
  methods: {
    addEntry: function () {
      EventBus.$emit('addRowMaterial', 'formular-material')
    },
    deleteEntry: function (vorgabenid) {
      // eslint-disable-next-line vue/no-mutating-props
      const arrayid = Vue._.findIndex(this.materialVorgabe, function (i) { return i.id === vorgabenid })
      console.log(arrayid)
      console.log(this.materialVorgabe)
      if (arrayid !== -1) {
        // eslint-disable-next-line vue/no-mutating-props
        this.materialVorgabe.splice(arrayid, 1)
      }

      console.log(this.materialVorgabe)
    }
  }
}
</script>

<style lang="scss">
  .repeater-drop-zone:first-child button[title="Remove block"]{
    display: none !important;
  }
  div.vorgaben {
    div.row {
      padding-right: 15px;
      padding-left: 15px;
      div.col-sm-2 {
      }
    }
  }
</style>
